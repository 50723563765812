/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./layout/header"
import Footer from "./layout/footer"
import {Helmet} from "react-helmet";

const Index = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="14f2ebd6-741f-4296-9c5b-79b643c0b58e" />
        <script type="text/javascript">
          {`function OptanonWrapper() { }`}
        </script>
        <script type="text/plain" className="optanon-category-C0002">
          {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', 'UA-2542116-28', 'auto');
          ga('send', 'pageview');
          `}
        </script>
        <script type="text/plain" className="optanon-category-C0004">
          {`(function() {
            var didInit = false;
            function initMunchkin() {
              if(didInit === false) {
                didInit = true;
                Munchkin.init('217-DHG-997', {"wsInfo":"hlRW"});
              }
            }
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//munchkin.marketo.net/munchkin.js';
            s.onreadystatechange = function() {
              if (this.readyState == 'complete' || this.readyState == 'loaded') {
                initMunchkin();
              }
            };
            s.onload = initMunchkin;
            document.getElementsByTagName('head')[0].appendChild(s);
          })();`}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      { children }
      <Footer />
    </>
  )
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Index

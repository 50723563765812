import React from "react"

export const Columns = ({ children }) => (
  <div className={`columns count-${React.Children.count(children)}`}>
    { children }
  </div>
)

export const Column = ({ children, extraClasses = '' }) => (
  <div className={`column ${extraClasses}`}>
    { children }
  </div>
)

import React from "react"
import { Link } from "gatsby"

const ButtonLink = ({ href, text, type = 'default' }) => (
  <Link to={href} className={`button ${type}`}>
    { text }
  </Link>
)

export default ButtonLink

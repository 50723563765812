import React from "react"
import { Link } from "gatsby"
import LogoColor from "../images/LogoColor";

const Logo = () => (
  <Link to="/" className="logo">
    <LogoColor />
  </Link>
)

export default Logo

import React from "react"

import Container from "./container"
import {
  Column,
  Columns,
} from "../elements/Columns";
import ButtonLink from "../elements/ButtonLink";
import LogoWhite from "../images/LogoWhite";
import {
  Link,
} from "gatsby";

const Footer = () => (
  <footer id="footer">
    <Container id="footer-main-wrap">
      <Columns>
        <Column>
          <div id="footer-logo">
            <LogoWhite />
          </div>
          <p><ButtonLink href="/request-demo" text="Schedule a Call" type="primary" /></p>
        </Column>
        <Column>
          <ul>
            <li><Link to="/terms-of-use/">Terms of Use</Link></li>
            <li><Link to="/terms-of-sale/">Terms of Sale</Link></li>
            <li><Link to="/privacy/">Privacy Statement</Link></li>
          </ul>
        </Column>
        <Column extraClasses="bold-links">
          <ul>
            <li><Link to="/insurers/"><b>For Health Insurers</b></Link></li>
            <li><Link to="/providers/"><b>For Healthcare Providers</b></Link></li>
            <li><Link to="/insights/"><b>INSIGHTS Paper</b></Link></li>
          </ul>
        </Column>
        <Column extraClasses="bold-links">
          <ul>
            <li><Link to="/resources/"><b>Resources</b></Link></li>
            <li><Link to="/about/"><b>About Us</b></Link></li>
            <li><Link to="/careers"><b>Careers</b></Link></li>
            <li>
              <button id="ot-sdk-btn" className="unstyled ot-sdk-show-settings">Do Not Sell My Personal Information</button>
            </li>
          </ul>
        </Column>
      </Columns>
    </Container>
    <Container id="footer-copyrights-wrap">
      &copy; { (new Date().getFullYear()) } InCircleReview
    </Container>
  </footer>
)

export default Footer

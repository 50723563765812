import React, {Component} from "react"
import { Link } from "gatsby"

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMobileMenu: false
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  };

  toggleMobileMenu() {
    console.log('setting status to', !this.state.openMobileMenu);
    this.setState({openMobileMenu: !this.state.openMobileMenu});
  };

  render() {
    return (
      <nav id="nav-main" className={`${this.state.openMobileMenu ? 'open' : ''}`}>
        <div id="nav-toggle-wrap">
          <span aria-hidden="true">Menu</span>
          <button id="nav-toggle" onClick={this.toggleMobileMenu}>
            <i/><i/><i/>
          </button>
        </div>
        <ul className="menu first-level">
          <li><Link activeClassName="active" to="/insurers/">For Health Insurers</Link></li>
          <li><Link activeClassName="active" to="/providers/">For Healthcare Providers</Link></li>
          <li><Link activeClassName="active" to="/insights/">INSIGHTS Paper</Link></li>
          <li><Link activeClassName="active" to="/resources/">Resources</Link></li>
          <li className="second-to-last">
            <Link activeClassName="active" to="/about/">About Us</Link>
            <ul className="second-level">
              <li><Link activeClassName="active" to="/careers/">Careers</Link></li>
            </ul>
          </li>
          <li className="green"><Link activeClassName="active" to="/request-demo/">Schedule a Call</Link></li>
        </ul>
      </nav>
    )
  }
}

import React from "react"
import Container from "./container";
import Logo from "./logo";
import Menu from "./menu";

import {
  FaEnvelope,
  FaPhone,
} from "react-icons/all";

const Header = () => (
  <header id="header">
    <Container id="logo-wrap">
      <div id="logo-wrap-inner" className="align-left">
        <Logo />
      </div>
      <div id="header-actions">
        <ul>
          <li><a href="mailto:info@incirclereview.com"><FaEnvelope /> <span className="hide-tablet">info@InCircleReview.com</span></a></li>
          <li><a href="tel:+18006595855"><FaPhone /> <span className="hide-tablet">(800) 659-5855</span></a></li>
        </ul>
      </div>
    </Container>
    <Container id="nav-main-wrap">
      <Menu />
    </Container>
  </header>
)

export default Header
